<template>
  <!-- 規則 -->
  <div class="about about_rule">
    <div class="tab_title">REGOLAMENTO</div>
    <div class="about_box container">
      <div class="about_img"><img src="images/about_pix03.png" alt="" /></div>
      <div class="about_txtarea">
        <div class="title">Regolamento</div>
        <div class="txt">
          <b>Scommesse :</b><br />Ogni Dirigibile Veneziano genera in maniera
          casuale 10 numeri, e vengono proposte decine di metodologie
          interessanti tra cui scegliere in base ai risultati della lotteria.
        </div>
        <div class="txt">
          <b>Quando giocare :</b><br />Comunichiamo le vincite ogni 90 secondi,
          960 volte al giorno, 365 giorni all'anno.
        </div>
        <div class="txt">
          <b>Risultati della lotteria :</b><br />Il Dirigibile Veneziano
          utilizza uno speciale sistema informatico per generare il numero
          vincente; i numeri verranno selezionati casualmente da 1 a 10. Vengono
          generati un totale di 10 numeri. L'ordine dei numeri va da sinistra a
          destra, dalla posizione 1 alla posizione 10, la prima occorrenza del
          numero raggiunge la posizione 1, la seconda occorrenza del numero alla
          posizione 2, e così via. Quando la decima cifra raggiunge la posizione
          10, l'estrazione si ferma e le 10 posizioni e i numeri corrispondenti
          diventano i numeri vincenti. I risultati del sorteggio vengono
          comunicati ogni 90 secondi e il numero vincente comparirà sul nostro
          sito ufficiale.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>