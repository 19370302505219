<template>
  <!-- 背景 -->
  <div class="about about_bg">
    <div class="tab_title">SFONDO</div>
    <div class="about_box container">
      <div class="about_img"><img src="images/about_pix02.png" alt="" /></div>
      <div class="about_txtarea">
        <div class="title">Sfondo del Dirigibile Veneziano</div>
        <div class="txt">
          Come rappresentante degli sport acquatici ad alta velocità, il
          Dirigibile può raggiungere la velocità di 250 km/h, con motore da 9600
          giri/min, e dato che la barca a motore può fluttuare sopra l'acqua, è
          stata battezzata Dirigibile Veneziano.
        </div>
        <div class="txt">
          Dai sarti ai piloti, ognuno ha sogni che volano nei nostri cuori;
          tutti vogliamo sfuggire alla gravità ed essere più liberi: l'arrivo
          del Dirigibile può far avverare i sogni di tutti.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>