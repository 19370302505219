<template>
  <footer>
    <!-- footer *** star -->
    <div class="footer">
      <div class="footer_img"><img src="images/footer.png" alt=""></div>
      <div class="footer_txt">IL GIOCO E' VIETATO AI MINORI<br>E PUÒ CAUSARE DIPENDENZA PATOLOGICA</div>
    </div>
    <!-- footer *** end -->
  </footer>
</template>

<script>
export default {
}
</script>