import Vue from 'vue';
import Vuex from 'vuex'
import App from './App.vue';
import router from './router';
import service from './service';
import i18n from './i18n';
import enums from './enums';

Vue.use(i18n);
Vue.config.productionTip = false;
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    issueNewest: {
      pk10: { drawNumbers: ['?', '?', '?', '?', '?', '?', '?', '?', '?', '?'] }
    }
  },
  mutations: {
    updateIssueNewest(state, value) {
      if (value && value.pk10) {
        state.issueNewest.pk10 = value.pk10;
      }
    }
  }
})

new Vue({
  provide() {
    return { service, enums };
  },
  store: store,
  router,
  render: h => h(App)
}).$mount('#app')

