import Vue from 'vue';
import VueRouter from 'vue-router';
import PK10View from './views/PK10';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'PK10',
    component: PK10View
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
