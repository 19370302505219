<template>
  <div>
    <div id="headerKeepMenu" class="header_keep" style="display: none;">
      <div class="container">
        <div class="logo fl">
          <a href="javascript:void(0);" @click="scrollToTag('topHeader')">
            <div class="screen-reader-text">Dirigibile veneziano</div>
          </a>
        </div>
        <ul class="menu fr">
          <li><a href="javascript:void(0);" @click="scrollToTag('curr')">Ultima estrazione</a></li>
          <li><a href="javascript:void(0);" @click="activeBtn='history';scrollToTag('infoNode')">Storico delle estrazioni</a></li>
          <li><a href="javascript:void(0);" @click="activeBtn='statistic';scrollToTag('infoNode')">Statistiche</a></li>
          <li><a href="javascript:void(0);" @click="activeBtn='about';scrollToTag('infoNode')">Sottoscritto</a></li>
          <li><a href="javascript:void(0);" @click="activeBtn='background';scrollToTag('infoNode')">Sfondo</a></li>
          <li><a href="javascript:void(0);" @click="activeBtn='rule';scrollToTag('infoNode')">Regolamento</a></li>
        </ul>
        <div class="menu_burger fr" @click="openRWDMenu('open')"></div>
      </div>
    </div>

    <div id="headerKeepMenuRWD" class="menu_rwd">
      <div class="menu_close" @click="openRWDMenu('close')"></div>
      <ul class="menu_rwd_box">
        <li class="active"><a href="javascript:void(0);" @click="scrollToTag('topHeader');openRWDMenu('close');">ULTIMA ESTRAZIONE</a></li>
        <li><a href="javascript:void(0);" @click="activeBtn='history';scrollToTag('infoNode');openRWDMenu('close');">STORICO DELLE ESTRAZIONI</a></li>
        <li><a href="javascript:void(0);" @click="activeBtn='statistic';scrollToTag('infoNode');openRWDMenu('close');">STATISTICHE</a></li>
        <li><a href="javascript:void(0);" @click="activeBtn='about';scrollToTag('infoNode');openRWDMenu('close');">SOTTOSCRITTO</a></li>
        <li><a href="javascript:void(0);" @click="activeBtn='background';scrollToTag('infoNode');openRWDMenu('close');">SFONDO</a></li>
        <li><a href="javascript:void(0);" @click="activeBtn='rule';scrollToTag('infoNode');openRWDMenu('close');">REGOLAMENTO</a></li>
      </ul>
    </div>

    <div class="main" id="main">
      <div class="main_box">
        <div class="header container" ref="topHeader">
          <div class="logo fl">
            <a href="javascript:void(0);">
              <div class="screen-reader-text">Dirigibile veneziano</div>
            </a>
          </div>
          <ul class="menu fr">
            <li><a href="javascript:void(0);" @click="scrollToTag('curr')">Ultima estrazione</a></li>
            <li><a href="javascript:void(0);" @click="activeBtn='history';scrollToTag('infoNode')">Storico delle estrazioni</a></li>
            <li><a href="javascript:void(0);" @click="activeBtn='statistic';scrollToTag('infoNode')">Statistiche</a></li>
            <li><a href="javascript:void(0);" @click="activeBtn='about';scrollToTag('infoNode')">Sottoscritto</a></li>
            <li><a href="javascript:void(0);" @click="activeBtn='background';scrollToTag('infoNode')">Sfondo</a></li>
            <li><a href="javascript:void(0);" @click="activeBtn='rule';scrollToTag('infoNode')">Regolamento</a></li>
          </ul>
          <div class="menu_burger fr" @click="openRWDMenu('open')"></div>
        </div>

        <div class="main_title">
          <div class="screen-reader-text">
            Elettrizzante e divertente gioco di barche da corsa virtuali
          </div>
        </div>
        <div class="main_sub">Aperto 24 ore al giorno</div>
        <div class="main_boat01 bounceInRight"></div>
        <div class="main_boat02 bounceInRight2"></div>
        <div class="main_boat03"></div>
        <div class="main_boat04"></div>
        <div class="main_tag" ref="curr"></div>
      </div>
      <div class="last_history_box container">
        <div class="last_history_title">
          <div class="screen-reader-text">Ultima estrazione</div>
        </div>
        <ul class="last_history_nums">
          <li v-for="(item, index) in newestInfo.drawNumber" :key="index">
            <div :class="'num00 num' + item">{{ (!isLoadFirst) ? '' : item }}</div>
            <div :class="'boat00 boat' + item" :id="'boat' + item"></div>
          </li>
        </ul>
      </div>
      <div class="line_01_01"></div>
      <div class="line_01_02"></div>
      <div class="line_01_03"></div>
      <div class="line_01_04"></div>
      <div class="line_01_05"></div>
    </div>

    <div class="intro">
      <div class="intro_box container">
        <div class="intro_01">
          <img src="images/intro/info_01.png" alt="" />
          <div class="txt">POTRESTI ESSERE TU <br />IL MILIONARIO!</div>
        </div>
        <div class="intro_02">
          <div class="txt">ESTRAZIONI OGNI <br />1 MINUTO E MEZZO</div>
          <img src="images/intro/info_02.png" alt="" />
        </div>
      </div>
      <div class="line_02_01"></div>
      <div class="line_02_02"></div>
      <div class="line_02_03"></div>
      <div class="line_02_04"></div>
      <div class="line_02_05"></div>
      <div class="line_02_06" ></div>      
      
      <div class="tabs_box container" >
        <ul class="tabs">
          <li @click="activeBtn='history'" :class="{active: activeBtn === 'history' }">
            <a href="javascript:void(0);" >STORICO DELLE<br />ESTRAZIONI</a>
          </li>
          <li @click="activeBtn='statistic'" :class="{active: activeBtn === 'statistic' }">
            <a href="javascript:void(0);" >STATISTICHE</a>
          </li>
          <li @click="activeBtn='about'" :class="{active: activeBtn === 'about' }">
            <a href="javascript:void(0);" >SOTTOSCRITTO</a>
          </li>
          <li @click="activeBtn='background'" :class="{active: activeBtn === 'background' }">
            <a href="javascript:void(0);" >SFONDO</a>
          </li>
          <li @click="activeBtn='rule'" :class="{active: activeBtn === 'rule' }">
            <a href="javascript:void(0);" >REGOLAMENTO</a>
          </li>
        </ul>
      </div>      
    </div>    

    <div class="infoNode" ref="infoNode"/>
    
    <pk-10-history v-show="activeBtn === 'history'" v/>
    <statistics-view v-show="activeBtn === 'statistic'"/>
    <pk-10-about v-show="activeBtn === 'about'"/>    
    <pk-10-background v-show="activeBtn === 'background'"/>
    <pk-10-rule v-show="activeBtn === 'rule'"/>
  </div>
</template>

<script>
import pk10History from "../components/PK10History.vue";
import statisticsView from '../components/StatisticsView.vue';
import pk10Rule from "../components/PK10Rule.vue";
import pk10Background from "../components/PK10Background.vue";
import pk10About from "../components/PK10About.vue";

const DEFAULT_SHIP_MOVE_SCALE = -50;
const DEFAULT_SHIP_MOVE_LARGE_SCALE = -35;
const DEFAULT_SHIP_MOVE_MIDDLE_SCALE = -22;

const REPEAT_TIME = 6000;

export default {
  inject: {
    service: "service"
  },
  components: {
    pk10History,
    statisticsView,
    pk10Rule,
    pk10Background,
    pk10About    
  },
  data() {
    return {
      activeBtn: 'history',
      newestInfo: [],
      smallBoat: [],
      isLoadFirst: false,
      scrollTagName: ''
    };
  },
  created(){
    this.newestInfo.drawNumber = [];
    for (let i = 1; i <= 10; i++) {
      this.newestInfo.drawNumber.push(this.padLeft(i, '0', 2));
    }      
    
    this.checkScrollPosition();
    let vm = this;
    window.addEventListener("scroll", function (e) {        
      vm.checkScrollPosition();
    });
  },
  methods: {
    scrollToTag(refName) {                
      this.scrollTagName = refName;   
      this.moveViewTag();
    },     
    moveViewTag(){
      let element = this.$refs[this.scrollTagName];
      let tagTop = element.offsetTop;
      
      window.scrollTo({top: (tagTop - $("#headerKeepMenu").height()), behavior: 'smooth'});

      this.scrollTagName = '';
    },
    setSmallBoatPos() {      
      var scale = DEFAULT_SHIP_MOVE_SCALE;
      var screentWidth = $(window).width();
      
      if (screentWidth < 580) {
        scale = DEFAULT_SHIP_MOVE_MIDDLE_SCALE;
      } else if (screentWidth < 1120) {
        scale = DEFAULT_SHIP_MOVE_LARGE_SCALE;
      }
      
      for (var i = 0; i < this.smallBoat.length; i++) {
        this.smallBoat[i].top = scale;          
      }
    },
    repeatMoveShip() {          
      for (var i = 0; i < this.smallBoat.length; i++) {           
        $('#' + this.smallBoat[i].id).css({ 
          top: this.smallBoat[i].top 
        });

        var num = this.padLeft((i + 1), '0', 2);

        (function (vnum, vid) {
          $('#' + vid)
            .removeClass('bounceInUp' + vnum)
            .addClass('bounceInUp' + vnum)
            .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $(this).removeClass('bounceInUp' + vnum);
            });
        })(num,  this.smallBoat[i].id);
      }
    },
    openRWDMenu(type) {
      type === "open"
        ? $("#headerKeepMenuRWD").addClass("is_open")
        : $("#headerKeepMenuRWD").removeClass("is_open");
    },
    checkScrollPosition() {
      var supportPageOffset = window.pageXOffset !== undefined;
      var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

      var y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
      if (y >= 100) {
          $("#headerKeepMenu").show();
      } else {
          $("#headerKeepMenu").hide();
      }
    }
  },
  watch:{
    '$store.state.issueNewest.pk10': function(newVal){           
      this.newestInfo = newVal[0];        
      this.smallBoat = [];
      for(let i=0; i < newVal[0].drawNumber.length; i++){
        this.smallBoat.push({ id: 'boat' + newVal[0].drawNumber[i] })
      } 

      this.$nextTick(function(){
        this.setSmallBoatPos();
        this.repeatMoveShip(); 

        setInterval(() => {        
          this.repeatMoveShip();        
        }, REPEAT_TIME);        
      });     

      this.isLoadFirst = true;
    }
  }
};
</script>