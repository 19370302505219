<template>
  <div>
    <office-header></office-header>
    <router-view></router-view>
    <office-footer></office-footer>
  </div>
</template>

<script>
import headerComponent from './components/Header';
import footerComponent from './components/Footer';

const executeDelay = 1500;
const maxExecuteTimes = 10;
export default {
  components: {
    officeHeader: headerComponent,
    officeFooter: footerComponent
  },
  inject: {
    service: 'service'
  },
  methods: {
    async getNewestAsync() {
        var response = await this.service.getHistoryDataAsync(); 

        if (response && response.length > 0) {      
          this.$store.commit('updateIssueNewest', { pk10: response });                       
        }
    }    
  },
  async created() {
    await this.getNewestAsync();
  }
}
</script>