import axios from 'axios';

const instance = axios.create({
    headers: { 'X-Requested-With': 'XMLHttpRequest' }
});

instance.defaults.baseURL = '/';
instance.interceptors.response.use(response => {
    return response.data.data;
}, error => {
    throw new Error('Error');
});

const get = (url, params) => instance.get(url, { params: { ...params, _: new Date().getTime() } });

export default { get };
