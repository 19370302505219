<template>
  <div class="statistics">
    <div class="tab_title">STATISTICHE</div>
    <div class="statistics_title container">
      <div class="statistics_date fl" id="statisticTimeRangeTxt"></div>
      <div class="statistics_btn" @click="openRWDDateTimeRangeTool()"></div>

      <ul class="statistics_tabs fr" id="statisticRangeRwdMenu">
        <li @click="setQueryRange(enums.STATISTIC_QUERY_RANGE.DAY)" :class="{ active: activeBtn === enums.STATISTIC_QUERY_RANGE.DAY }">
          <a href="javascript:void(0);">GIORNO SUCCESSIVE</a>
        </li>
        <li @click="setQueryRange(enums.STATISTIC_QUERY_RANGE.WEEK)" :class="{ active: activeBtn === enums.STATISTIC_QUERY_RANGE.WEEK }">
          <a href="javascript:void(0);">SETTIMANA SUCCESSIVE</a>
        </li>
        <li @click="setQueryRange(enums.STATISTIC_QUERY_RANGE.TWO_WEEK)" :class="{ active: activeBtn === enums.STATISTIC_QUERY_RANGE.TWO_WEEK }">
          <a href="javascript:void(0);">PROSSIME DUE SETTIMANE</a>
        </li>
        <li @click="setQueryRange(enums.STATISTIC_QUERY_RANGE.MONTH)" :class="{ active: activeBtn === enums.STATISTIC_QUERY_RANGE.MONTH }">
          <a href="javascript:void(0);">PROSSIMO MESE</a>
        </li>
      </ul>
    </div>
    <div class="center" v-show="showLoading">
      <div class="loading-bro">
        <h1>Loading...</h1>
        <svg id="load" x="0px" y="0px" viewBox="0 0 150 150">
          <circle id="loading-inner" cx="75" cy="75" r="60"></circle>
        </svg>
      </div>
    </div>

    <div class="statistics_box container" id="rankingListBox">
      <div v-for="(itemData, index) in statisticData" :key="index" :class="'statistics_sort statistics_' + padLeft(index + 1, '0', 2)">
        <div class="boat fl"></div>
        <div class="sort_box container">
          <div v-for="(item, index2) in itemData" :key="index2" :class="'sort ' + (index2 < 3 ? 'sort_org' : '')">
            <div class="times">{{ item }}</div>
            <div class="bar chartBounceInUp" :style="'height:' + barHight(item, maxValue(itemData)) + 'px;'"></div>
            <div class="rank">{{ index2 + 1 }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistics_bg"></div>
  </div>
</template>

<script>
const BAR_MAX_HEIGHT = 65;
const RANKING_SIZE = 10;

export default {
  inject: {
    service: "service",
    enums: "enums"
  },
  data() {
    return {
      activeBtn: this.enums.STATISTIC_QUERY_RANGE.DAY,
      statisticData: [],
      showLoading: true
    };
  },
  mounted() {
    this.updateStatisticTimeRangeTxt();
    this.setDefaultStatisticData();    
  },
  methods: {
    async getStatisticDataAsync(days) {
      this.showLoading = true;
      var response = await this.service.getStatisticDataAsync(days);      
      this.statisticData = response;
      this.showLoading = false;      
    },
    setQueryRange(timeRange){
      if (this.showLoading){
        return;
      }
      
      this.activeBtn = timeRange;
    },
    updateStatisticTimeRangeTxt(){
      let todayDate = this.getZoneDate();
      var dayIdx = todayDate.getDay();
      var zoneDayName = dateOpts.dayNames[dayIdx];
      var zoneMonthName = dateOpts.monthNames[todayDate.getMonth()];

      var todayStr = zoneDayName + ' ' + todayDate.getDate() + ' ' + zoneMonthName + ' ' + todayDate.getFullYear();
      todayStr = todayStr.toUpperCase();

      let days = 1;
      switch (this.activeBtn) {
        case this.enums.STATISTIC_QUERY_RANGE.WEEK:
          days = 7;
          break;
        case this.enums.STATISTIC_QUERY_RANGE.TWO_WEEK:
          days = 14;
          break;
        case this.enums.STATISTIC_QUERY_RANGE.MONTH:
          days = 30;
          break;
      }

      var nearDate = new Date();
      nearDate.setDate(todayDate.getDate() - days);

      var nearDayName = dateOpts.dayNames[nearDate.getDay()];
      var nearMonthName = dateOpts.monthNames[nearDate.getMonth()];
      var nearDateStr = nearDayName + ' ' + nearDate.getDate() + ' ' + nearMonthName + ' ' + nearDate.getFullYear();
      nearDateStr = nearDateStr.toUpperCase();

      $('#statisticTimeRangeTxt').html(nearDateStr + ' ~ <br> ' + todayStr);      
      $("#statisticRangeRwdMenu").removeClass('is_open');

      this.getStatisticDataAsync(days);
    },
    openRWDDateTimeRangeTool() {
      if ($("#statisticRangeRwdMenu").css("display") === "none") {
        $("#statisticRangeRwdMenu").addClass("is_open");
      } else {
        $("#statisticRangeRwdMenu").removeClass("is_open");
      }
    },
    setDefaultStatisticData() {
      this.statisticData = [];
      for (var i = 0; i < RANKING_SIZE; i++) {
        var zeroArray = [];
        for (var j = 0; j < RANKING_SIZE; j++) {
          zeroArray.push(0);
        }
        this.statisticData.push(zeroArray);
      }
    },
    barHight(item, maxVal) {
      var barHeight = 1;
      if (maxVal > 0) {
        barHeight =
          maxVal === item ? BAR_MAX_HEIGHT : BAR_MAX_HEIGHT * (item / maxVal);
      }
      return barHeight;
    },
    maxValue(item) {
      return Math.max.apply(null, item);
    }
  },
  watch: {
    activeBtn: function (v) {      
      this.updateStatisticTimeRangeTxt();
    }
  }
};
</script>