<template>
  <!-- 關於 -->
  <div>
    <div class="about about_sott">
      <div class="tab_title">SOTTOSCRITTO</div>
      <div class="about_box container">
        <div class="about_img"><img src="images/about_pix01.png" alt="" /></div>
        <div class="about_txtarea">
          <div class="title">A proposito del Dirigibile Veneziano</div>
          <div class="txt">
            Il Dirigibile Veneziano è emesso esclusivamente dalla Commissione
            congiunta di Welfare di Venezia.
          </div>
          <div class="txt">
            La modalità di gioco è semplice, le regole complicate sono state
            rese facili da capire, e tutti possono sentire la gioia della
            vittoria con il Dirigibile Veneziano. Il risultato del sorteggio
            viene proiettato ogni 90 secondi, per un totale di 960
            visualizzazioni al giorno.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>