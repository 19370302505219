<template>
  <div class="history">
    <div class="tab_title">STORICO DELLE ESTRAZIONI</div>
    <div class="history_box container">
      <div class="calendar fl">
        <div class="bui_calendar_main container">
          <div style="width: 100%; border: 0px" id="datepicker"></div>
        </div>
        <div class="calendar_time container">
          <div class="icon_calendar_time fl"></div>
          <select id="calendarTime" class="calendar_time_select fl" @click="setTimeOptionsDisable();" v-model="selectHour">
            <option v-for="(index) in 24" :key="(index - 1)" :value="padLeft((index - 1), '0', 2)" :disabled="(index - 1) > limitHour" >
              {{ padLeft((index - 1), '0', 2) + ':00' }}
            </option>
          </select>
        </div>
        <div class="calendar_btn container">
          <a href="javascript:void(0);" class="btn" @click="searchTrendHistory('datepicker', 'calendarTime')">CERCA</a>
        </div>
      </div>
      <div class="bui_history_main fr">
        <div class="history_title">
          <div class="txt" >{{searchTimeTxt}}</div>
          <div class="history_calendar_btn" id="historyCalendarRWDBtn" @click="openRWDDateTimeTool()"></div>
          <div class="calendar calendar_rwd" id="calendarRWDMenu">
            <div class="bui_calendar_main container">
              <div style="width: 100%; border: 1px" id="datepicker2"></div>
            </div>
            <div class="calendar_time container">
              <div class="icon_calendar_time fl"></div>
              <select id="calendarTime2" class="calendar_time_select fl" @click="setTimeOptionsDisable();" v-model="selectHour">
                <option v-for="(index) in 24" :key="(index - 1)" :value="padLeft((index - 1), '0', 2)" :disabled="(index - 1) > limitHour">
                  {{ padLeft((index - 1), '0', 2) + ':00' }}
                </option>
              </select>
            </div>
            <div class="calendar_btn container">
              <a href="javascript:void(0);" class="btn" @click="searchTrendHistory('datepicker2', 'calendarTime2')">CERCA</a>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="loading-bro" v-show="loading">
            <h1>Loading...</h1>
            <svg id="load" x="0px" y="0px" viewBox="0 0 150 150">
              <circle id="loading-inner" cx="75" cy="75" r="60"></circle>
            </svg>
          </div>
        </div>
        <div class="history_list_box">
          <div class="history_list" v-for="(issueInfo, index) in pageData" :key="index">
            <div class="issue">NUMERO : <span>{{ issueInfo.issueNo }}</span></div>
            <div class="time">{{ issueInfo.issueTime }}</div>
            <ul class="nums" >
              <li v-for="(awardNum, index2) in issueInfo.drawNumber" :key="index2" :class="'num_' + awardNum">{{ awardNum }}</li>
            </ul>
          </div>
        </div>
        <div class="history_pagination" v-if="this.totalPage !== 0">
          <div class="nums_box">
            <a href="javascript:void(0);" @click="previousPage()" class="history_pagination_pre " :class="currentPage <= 1 ? 'history_pagination_disable' : ''"></a>
            <a href="javascript:void(0);" @click="nextPage()" class="history_pagination_next" :class="currentPage >= totalPage ? 'history_pagination_disable' : ''"></a>
            <div id="pageNumberContainer">
              <div class="num" v-for="index in totalPage" :key="index" :class="{active: index === currentPage }" @click="setPage(index)">{{ index }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="history_bg"></div>
  </div>
</template>

<script>
const PAGE_SIZE = 13;	//per page count

export default {
  inject: {
    service: 'service'
  },
  data() {
    return {
      historyData: [],
      pageData: [],      
      totalPage: 0,
      currentPage: 1,
      loading: true,
      selectHour: '',
      limitHour: '',
      currentDateTime: null,      
      isLastDay: true,
      storeDatepickerSelect: null,
      searchTimeTxt: ''
    };
  },
  methods: {
    setTotalPageCount() {        
      this.totalPage = Math.floor(this.historyData.length / PAGE_SIZE) + (this.historyData.length % PAGE_SIZE > 0 ? 1 : 0);
      this.currentPage = 1;
    },    
    updateAwardNumHistoryList() {
      let calStartRecord = (this.currentPage - 1) * PAGE_SIZE;
      let calEndRecord = this.currentPage * PAGE_SIZE;
      
      if (calEndRecord > this.historyData.length) {
          calEndRecord = this.historyData.length;
      }
      
      this.pageData = [];
      for (let i = calStartRecord; i < calEndRecord; i++) {
        this.pageData.push(this.historyData[i]);      
      }    
    },   
    previousPage() {      
      if (this.currentPage - 1 >= 1){
        this.currentPage--;
      }      
      this.updateAwardNumHistoryList();
    },
    nextPage() {
      if (this.currentPage + 1 <= this.totalPage){
        this.currentPage++;
      }
      this.updateAwardNumHistoryList();
    },
    setPage(page) {      
      this.currentPage = page;
      this.updateAwardNumHistoryList();
    },
    searchTrendHistory(datepickName, timeName) {
      let choiceDate = $("#" + datepickName).datepicker("getDate");
      if (choiceDate) {
        let hour = parseInt($("#" + timeName).val().replace(":00", ""));
        choiceDate.setHours(hour);    

        this.getHistoryDataAsync(choiceDate);         
      }

      if (datepickName === "datepicker2") {
        this.openRWDDateTimeTool();
      }
    },
    async getHistoryDataAsync(choiceDate) {      
      this.loading = true;

      let searchDate = choiceDate.getFullYear() + '-' + 
        this.padLeft((choiceDate.getMonth() + 1), '0', 2) + '-' + 
        choiceDate.getDate() + ' ' + 
        this.padLeft(choiceDate.getHours(), '0', 2) 
        + ':00:00';  

      var response = await this.service.getHistoryDataAsync(searchDate);       
   
      this.historyData = response;        
      this.loading = false;
      this.showQueryTimeTxt(choiceDate);
      this.setTotalPageCount();    
      this.updateAwardNumHistoryList();
    },
    showQueryTimeTxt(choiceDate){      
      let dayIdx = choiceDate.getDay();
      let zoneDayName = dateOpts.dayNames[dayIdx];
      let zoneMonthName = dateOpts.monthNames[choiceDate.getMonth()];

      let todayStr = zoneDayName + ' ' + choiceDate.getDate() + ' ' + zoneMonthName + ' ' + choiceDate.getFullYear() + ', ' + choiceDate.getHours();
      this.searchTimeTxt = todayStr.toUpperCase();
    },
    openRWDDateTimeTool() {
      if ($("#calendarRWDMenu").css("display") === "none") {
        $("#calendarRWDMenu").addClass("is_open");
      } else {
        $("#calendarRWDMenu").removeClass("is_open");
      }
    },
    setTimeOptionsDisable() {
      this.currentDateTime = this.getZoneDate();

      if (typeof(this.storeDatepickerSelect.selectedDay) === 'string') {
          this.storeDatepickerSelect.selectedDay = this.betterParseInt(this.storeDatepickerSelect.selectedDay);
      }

      var d1 = new Date(this.storeDatepickerSelect.selectedYear, 
        this.storeDatepickerSelect.selectedMonth, 
        this.storeDatepickerSelect.selectedDay);
      
      var d2 = new Date(this.currentDateTime.getFullYear(), 
        this.currentDateTime.getMonth(),
        this.currentDateTime.getDate());

      if (d2.getTime() > d1.getTime()) {
        $("#calendarTime option").each(function () {
            $(this).attr('disabled', null);
        });

        $("#calendarTime2 option").each(function () {
            $(this).attr('disabled', null);
        });        

        this.isLastDay = false;

      } else if (!this.isLastDay) {        
        this.operateCalendarTime('calendarTime')
        this.operateCalendarTime('calendarTime2') 

        this.isLastDay = true;
      }
    },
    operateCalendarTime(calendarName) {      
      let optSelectIdx = $('#' + calendarName)[0].selectedIndex;
      let currentHour = this.currentDateTime.getHours();

      $('#' + calendarName + ' option').each(function (idx) {        
        if (idx > currentHour) {
          $(this).attr('selected', false);
        }
        if (idx > currentHour) {
          $(this).attr('disabled', 'disabled');
        }
      });

      if (optSelectIdx > currentHour) {
        $('#' + calendarName).val(this.padLeft(currentHour, '0', 2) + ':00');
      }
    }
  },
  watch: {
    "$store.state.issueNewest.pk10": function (newVal) {      
      this.historyData = newVal;    
      this.loading = false;
      this.setTotalPageCount();    
      this.updateAwardNumHistoryList();            
    }
  },
  mounted() {
    this.currentDateTime = this.getZoneDate();    
    let currentYear = this.currentDateTime.getFullYear();
    let currentMonth = this.currentDateTime.getMonth();
    let currentDay = this.currentDateTime.getDate();

    dateOpts.maxDate = new Date(currentYear, currentMonth, currentDay);

    $.datepicker.setDefaults($.datepicker.regional['']);

    let vm = this;
    dateOpts.onSelect = (dateText, e) => {
      vm.storeDatepickerSelect = e;
      vm.setTimeOptionsDisable();
    };

    $('#datepicker').datepicker(dateOpts);
    $('#datepicker2').datepicker(dateOpts);       

    this.limitHour = this.selectHour = this.padLeft(this.currentDateTime.getHours(), '0', 2);

    this.showQueryTimeTxt(this.currentDateTime);

    this.storeDatepickerSelect = {
      selectedYear: currentYear,
      selectedMonth: currentMonth,
      selectedDay: currentDay
    };
  }
};
</script>